@use '~@angular/material' as mat;

@include mat.core();
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';

/* Tailwind styles */
@import 'bootstrap/dist/css/bootstrap.min';
@import './tailwind';



// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include cdk-overlay();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$anectodes-frontend-primary: mat-palette($mat-indigo);
$anectodes-frontend-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$anectodes-frontend-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$anectodes-frontend-theme: mat-light-theme(
  (
    color: (
      primary: $anectodes-frontend-primary,
      accent: $anectodes-frontend-accent,
      warn: $anectodes-frontend-warn,
    ),
  )
);

/*  Tailwind fallbacks
    Tailwind assigns some styles for elements and classes that our system is not ready for yet */
svg {
  display: initial;
}
/* End Tailwind fallbacks */

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
// @include angular-material-theme($anectodes-frontend-theme);

//Include colors to background depending on pluginId
//To use it, need to append 'bg-' to pluginId
/*
Usage example:
class="plugin-bg-{{pluginId}}"// translated : class="plugin-bg-okta"
*/
@import './plugin-config.scss';

/* You can add global styles to this file, and also import other style files */
@import 'index';
@import './typography.scss';
@import 'base-modal';
@import './scrollbar';
// @import "~@angular/material/prebuilt-themes/indigo-pink.css";

/* You can add global styles to this file, and also import other style files */

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@import './btn-group.scss';
@import './button.scss';

@font-face {
  font-family: 'Muli';
  src: url($ASSETS_PATH + '/fonts/muli/Muli-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Mulish';
  src: url($ASSETS_PATH + '/fonts/mulish/Mulish-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Merriweather';
  src: url($ASSETS_PATH + '/fonts/merriweather/Merriweather-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Merriweather-Bold';
  src: url($ASSETS_PATH + '/fonts/merriweather/Merriweather-Bold.ttf') format('truetype');
}

mat-icon {
  font-family: 'Material Icons' !important;
}

.mat-expansion-panel-content {
  visibility: unset !important;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: $mc-background;
}

body {
  margin: 0;
  overflow: hidden;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.center-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.bold {
  font-weight: bold !important;
}

/* Default popover */
$popover-background-color: $mc-navy-80;

.popover {
  border: none;
}

.default-popover {
  background: $popover-background-color;
  font-size: 125%;
  border-radius: 10px;

  .popover-container {
    padding: 0 17px 0 17px;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .popover-body {
    padding: 0;
    height: 40px;
  }

  > * {
    @include basicWhite();
  }

  & .arrow::after {
    color: $mc-white;
    border-right-color: $popover-background-color;
  }

  & .arrow::before {
    border: none;
  }
}

.copy-tooltip {
  top: 30px !important;

  .arrow {
    left: calc(50% - 0.4rem) !important;
  }
}

.connection-error-tooltip {
  overflow-wrap: anywhere;
  .tooltip-inner {
    max-width: 600px !important;
  }
}

.date-info-top-left-tooltip {
  left: -13px !important;
}

.default-tooltip,
.header-item-info,
.required-mark-tooltip {
  * {
    @include MulishFontStyle();
    color: $mc-white;
  }

  &.show {
    opacity: 1;
  }

  &.bs-tooltip-bottom {
    .arrow {
      &::before {
        border-bottom-color: $popover-background-color;
      }
    }
  }

  &.bs-tooltip-left {
    .arrow {
      &::before {
        border-left-color: $popover-background-color;
      }
    }
  }

  &.bs-tooltip-top {
    .arrow {
      &::before {
        border-top-color: $popover-background-color;
      }
    }
  }

  &.bs-tooltip-right {
    .arrow {
      &::before {
        border-right-color: $popover-background-color;
      }
    }
  }

  .tooltip-inner {
    background: $popover-background-color;
    display: flex;
    align-items: center;
    text-align: left;
    border-radius: 6px;
    min-height: 40px;
    max-width: 327px;
    padding: 10px;
  }
}

.header-item-info {
  top: 3px !important;
  .tooltip-inner {
    padding: 15px 15px;
  }
}

.searchable-tt {
  .tooltip-inner {
    @apply max-w-75 max-h-75 overflow-y-scroll;
  }
}

.required-mark-tooltip {
  top: -7px !important;
}

.evidence-status-tooltip {
  left: 3px !important;
}

.element-help-tooltip {
  left: -11px !important;
}

.evidence-help-tooltip {
  top: 3px !important;
  left: -10px !important;
  .tooltip-inner {
    max-width: fit-content;
    min-width: $evidence-help-width;
  }
}

.evidence-help-tooltip-right {
  top: 3px !important;
  left: 10px !important;
  .tooltip-inner {
    max-width: fit-content;
    min-width: $evidence-help-width;
  }
}

.framework-icon-tooltip {
  max-width: fit-content;
  text-align: center;
  white-space: pre-line;

  .tooltip-inner {
    text-align: center;
  }

  .arrow {
    margin-left: -6px;
  }
}

/* End Default popover */

// Styles for scroll bar

.ps__rail-y {
  width: 6px !important;
  opacity: 1 !important;
}

.ps__rail-x {
  height: 6px !important;
  opacity: 1 !important;
}

.ps__thumb-y {
  right: 0 !important;
  width: 6px !important;
  background: #cbd2d6 !important;
  border-radius: 30px !important;
}

.ps__thumb-x {
  height: 6px !important;
  bottom: 0 !important;
  background: #cbd2d6 !important;
}

button,
button:focus {
  outline: none;
  border: none;
}

button {
  cursor: pointer;

  &.disabled {
    cursor: default;
  }
}

.error-message {
  @include baseFontStyle();
  color: $mc-text-error;
}

.hidden,
[hidden] {
  display: none !important;
}

.common-container {
  position: relative;
  margin: 20px 25px;
}

body .main-title {
  @include MulishBold();
  white-space: normal;

  span.title {
    @include basicLight();
    font-weight: bold;
  }
}

.help-block {
  @include MulishFontStyle();
  color: $mc-pink-50;
  margin-top: 5px;
}
