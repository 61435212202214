@import './mixins/fonts';
@import './variables/_button.variables.scss';

.btn {
  @apply inline-flex flex-row justify-evenly items-center;
  @apply relative overflow-hidden;
  @apply cursor-pointer;
  @apply font-main text-base;
  @apply relative overflow-hidden;
  gap: $distance-between-icon-and-text;
  padding: 0 $padding-right-left;

  svg-icon {
    @apply min-w-5 max-w-5 min-h-5 max-h-5;
  }

  app-circle-loader {
    width: $loading-circle-size;
    height: $loading-circle-size;
  }

  &[type='primary'][bg-mode='light'],
  &[type='secondary'][bg-mode='light'],
  &[type='basic'][bg-mode='light'] {
    &[disabled],
    &.disabled,
    &[disabled].active,
    &.disabled.active {
      @apply text-navy-60 bg-transparent border-none pointer-events-none #{!important};

      .font-color {
        @apply fill-navy-60 #{!important};

        * {
          @apply fill-navy-60 #{!important};
        }
      }

      .font-color-stroke {
        @apply stroke-navy-60 #{!important};

        * {
          @apply stroke-navy-60 #{!important};
        }
      }
    }
  }

  &[type='primary'][bg-mode='light'] {
    @apply bg-navy-80 text-white rounded-md;

    .font-color {
      @apply fill-white;

      * {
        @apply fill-white;
      }
    }

    .font-color-stroke {
      @apply stroke-white;

      * {
        @apply stroke-white;
      }
    }

    &.focused,
    &:hover {
      @apply bg-navy-90;
    }
  }

  &[type='secondary'][bg-mode='light'] {
    @apply bg-transparent text-navy-90;
    @apply border border-navy-60 rounded-md;

    &.loading {
      @apply bg-navy-40;
    }

    app-circle-loader {
      .loaderQuart {
        @apply border-navy-40;

        &:after {
          border-top-color: $mc-navy-90;
        }
      }
    }

    &.focused,
    &:hover {
      @apply bg-navy-40 #{!important};
    }
  }

  &[type='secondary'][bg-mode='light'],
  &[type='secondary'][bg-mode='light']:hover {
    .font-color {
      @apply fill-navy-90 #{!important};

      * {
        @apply fill-navy-90 #{!important};
      }
    }

    .font-color-stroke {
      @apply stroke-navy-90 #{!important};

      * {
        @apply stroke-navy-90 #{!important};
      }
    }
  }

  &[bg-mode='dark'] {
    @apply bg-transparent text-white;
    @apply border border-white;

    .font-color {
      @apply fill-white;

      * {
        @apply fill-white;
      }
    }

    .font-color-stroke {
      @apply stroke-white;

      * {
        @apply stroke-white;
      }
    }

    &.focused,
    &:hover {
      @apply bg-white text-navy-90;

      .font-color {
        @apply fill-navy-90;

        * {
          @apply fill-navy-90;
        }
      }

      .font-color-stroke {
        @apply stroke-navy-90;

        * {
          @apply stroke-navy-90;
        }
      }
    }
  }

  &[bg-mode='dark'][disabled],
  &[bg-mode='dark'].disabled,
  &[bg-mode='dark'][disabled].active,
  &[bg-mode='dark'].disabled.active {
    @apply text-navy-70 bg-transparent border border-navy-70 pointer-events-none #{!important};

    .font-color {
      @apply fill-navy-70 #{!important};

      * {
        @apply fill-navy-70 #{!important};
      }
    }

    .font-color-stroke {
      @apply stroke-navy-70 #{!important};

      * {
        @apply stroke-navy-70 #{!important};
      }
    }
  }

  &[type='basic'][bg-mode='light'] {
    @apply bg-transparent text-navy-90;
    @apply border-transparent;

    .font-color {
      @apply fill-navy-90;

      * {
        @apply fill-navy-90;
      }
    }

    .font-color-stroke {
      @apply stroke-navy-90;

      * {
        @apply stroke-navy-90;
      }
    }

    &.focused,
    &:hover {
      @apply bg-navy-40;
    }
  }

  &[type='basic'][bg-mode='dark'] {
    @apply border-transparent #{!important};
  }

  &[type='basic'][bg-mode='dark'][disabled],
  &[type='basic'][bg-mode='dark'].disabled {
    @apply border-none #{!important};
  }

  &[bg-mode='dark'].loading {
    @apply border border-white #{!important};
    @apply bg-white #{!important};
  }

  &[type='primary'],
  &[type='secondary'],
  &[type='basic'] {
    &.active {
      @apply bg-blue-90-15 text-navy-90 #{!important};
      @apply border border-blue-90-20 #{!important};

      &.focused,
      &:hover {
        @apply bg-blue-90-20 #{!important};
        @apply border-navy-60 #{!important};
      }

      .font-color {
        @apply fill-navy-90 #{!important};

        * {
          @apply fill-navy-90 #{!important};
        }
      }

      .font-color-stroke {
        @apply stroke-navy-90 #{!important};

        * {
          @apply stroke-navy-90 #{!important};
        }
      }
    }
  }

  &.small {
    @apply w-10;
  }

  &.medium {
    @apply w-40;
  }

  &.stretch {
    @apply w-full;
  }

  &.responsive {
    @apply px-5;
  }

  &.fixed160 {
    @apply w-40;
  }

  /* Vertical size */

  &.vs {
    &-medium {
      @apply h-10;
    }

    &-small {
      @apply h-8;
    }
  }

  /* End vertical size */

  .overlay {
    display: none;
  }

  &.focused,
  &:hover {
    .overlay {
      @apply flex justify-center items-center;
      @apply w-full h-full;
      @apply absolute;
      margin: 1px; // fixes size of button when there is no border
      gap: $distance-between-icon-and-text;
    }
  }
}
