*::-webkit-scrollbar-track {
  display: none;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  border-radius: 6px;
  -webkit-box-shadow: inset -1px 0px 20px 0px #aaa;
  box-shadow: inset -1px 0px 20px 0px #aaa;
}
